import './Icon.scss'

interface IconProps {
  icon?: string;
  className?: string;
  outerStyles?: object;
  innerStyles?: object;
  onClick?: () => void;
  size?: number
  importedImage?: any
  innerClassName?: string
  testid?: string
}

const Icon: React.FC<IconProps> = ({
  icon, className, outerStyles, innerStyles,
  onClick, size, importedImage, innerClassName, testid,
}) => (
  <div
    className={`outer-icon ${className}`}
    style={outerStyles || {}}
    onClick={onClick}
    data-cy={testid}
  >
    <img
      src={importedImage || `/icons/${icon}.svg`}
      className={`inner-icon ${innerClassName || 'h-[16px] w-[16px]'}`}
      style={{ ...innerStyles, height: size, width: size }}
      alt="icon"
    />
  </div>
)

export default Icon
