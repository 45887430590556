import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IUser } from '../../../../../shared/src/sharedTypes'
import { createTrackingPoint } from '../../../functions/juneAnalytics'
import { State } from '../../../redux/store'

const useSendTrackingPointOnPageLoad = (taskName: string) => {
  const user = useSelector<State, IUser>((state) => state.user)

  useEffect(() => {
    createTrackingPoint(taskName, user)
  }, [])
}

export default useSendTrackingPointOnPageLoad
