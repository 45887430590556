import { useDispatch, useSelector } from 'react-redux'
import { cardStateType } from '../../../../../redux/cardReducer'
import { State } from '../../../../../redux/store'

export default () => {
  const dispatch = useDispatch()
  const dispatchCard = (payload: Partial<cardStateType>) => dispatch({ type: 'card/update', payload })
  const cardState = useSelector<State, cardStateType>((state) => state.card)
  return [cardState, dispatchCard] as const
}
