import { createReducer } from '@reduxjs/toolkit'
import { IUserSession } from '../../../shared/src/sharedTypes'
import { IExtendedCard } from '../components/Screens/CardScreen/Card/Utils/CardTypes'

export type SessionStateType = {
  sessionType: IUserSession['sessionType'] | undefined
  timerStartedAt: Date | undefined
  showLeavePageScreen: boolean
  cardsSeen: IExtendedCard[]
  correctAnswers: number
  currentDate: Date
  sessionEndedAt: Date | undefined
  newCardsCount: number
  cardsFlippedCount: number
  nativeLanguage: string
}

const initialSessionsState: SessionStateType = {
  sessionType: undefined,
  correctAnswers: 0,
  timerStartedAt: undefined,
  showLeavePageScreen: false,
  // unique cards seen
  cardsSeen: [],
  // total cards flipped
  cardsFlippedCount: 0,
  currentDate: new Date(),
  sessionEndedAt: undefined,
  newCardsCount: 0,
  nativeLanguage: 'EN',
}

export default createReducer(
  initialSessionsState,
  {
    'session/update': (state, action) => {
      const body: Partial<SessionStateType> = action.payload
      if (body) {
        const newState = { ...state }

        newState.sessionType = body.sessionType ?? state.sessionType
        newState.timerStartedAt = body.timerStartedAt ?? state.timerStartedAt
        newState.showLeavePageScreen = body.showLeavePageScreen ?? state.showLeavePageScreen
        newState.cardsSeen = body.cardsSeen ?? state.cardsSeen
        newState.cardsFlippedCount = body.cardsFlippedCount ?? state.cardsFlippedCount
        newState.correctAnswers = body.correctAnswers ?? state.correctAnswers
        newState.currentDate = body.currentDate ?? state.currentDate
        newState.sessionEndedAt = body.sessionEndedAt ?? state.sessionEndedAt
        newState.newCardsCount = body.newCardsCount ?? state.newCardsCount
        newState.nativeLanguage = body.nativeLanguage ?? state.nativeLanguage

        return newState
      }
    },
    'session/reset': () => initialSessionsState,
  },
)
