import { createReducer } from '@reduxjs/toolkit'
import { SpeakerAudioDestination } from 'microsoft-cognitiveservices-speech-sdk'
import { DeckIdType } from '../../../shared/src/sharedTypes'

export interface ModalState {
  isOpen: boolean,
  content?: JSX.Element | null,
  cantDismiss?: boolean
}

export interface GeneralStateType {
  statisticsDeck: DeckIdType | 'All'
  isLanguageSelectionModalOpen: boolean
  player: SpeakerAudioDestination | undefined
  modal: ModalState | undefined
}

export const DEFAULT_MODAL_STATE = {
  isOpen: false,
  content: null,
  cantDismiss: false,
}

const initialState: GeneralStateType = {
  statisticsDeck: 'All',
  isLanguageSelectionModalOpen: false,
  player: undefined,
  modal: DEFAULT_MODAL_STATE,
}

const reducer = createReducer(initialState, {
  'general/update': (state, action) => {
    state.player = action.payload.player
    state.statisticsDeck = action.payload.statisticsDeck ?? state.statisticsDeck
    state.modal = action.payload.modal ?? state.modal
    state.isLanguageSelectionModalOpen = action.payload.isLanguageSelectionModalOpen
      ?? state.isLanguageSelectionModalOpen
  },
})

export default reducer
