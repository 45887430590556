import Cookies from 'js-cookie'
import _ from 'lodash'
import {
  AudioConfig, SpeakerAudioDestination, SpeechConfig, SpeechSynthesizer,
} from 'microsoft-cognitiveservices-speech-sdk'
import { useQuery } from 'react-query'
import { apiGet } from '../../../../../functions/generic'
import useGeneralState from '../../../../../redux/hooks/useGeneralState'
import useUserState from '../../../../../redux/hooks/useUserState'
import { useGetActiveVoice } from '../../../../LearningPreferences/AccentSetting'
import useCardState from '../hooks/useCardState'

export interface VoiceType {
  DisplayName: string
  Gender: string
  LocalName: string
  Locale: string
  LocaleName: string
  Name: string
  SampleRateHertz: string
  ShortName: string
  Status: string
  VoiceType: string
  WordsPerMinute: string
}

export const useGetAvailableVoices = () => useQuery(
  ['/api/available-speech-voices'],
  () => apiGet<{ token: VoiceType[] }>('/api/available-speech-voices'),
  {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 60000, // Set a cache time of 1 minute (in milliseconds)
  },
)

export const getVoices = async (lastLearnedLanguage: string) => {
  const lastLearnedLanguageCookie = Cookies.get('last-learned-language')
  const cookieVoices = Cookies.get('speech-voices')

  const getUniqueVoices = (voices: VoiceType[]) => _.uniq(voices?.map((voice) => voice.Locale))
    .filter((language: any) => language.split('-')[0] === lastLearnedLanguage?.toLowerCase()) as string[]

  if (!cookieVoices || lastLearnedLanguageCookie !== lastLearnedLanguage) {
    const data = await apiGet<{ token: VoiceType[] }>('/api/available-speech-voices')

    const voices = getUniqueVoices(data?.token || [])

    Cookies.set('speech-voices', JSON.stringify(voices), { expires: 1 / 24 / 6 })
    Cookies.set('last-learned-language', lastLearnedLanguage, { expires: 1 / 24 / 6 })
    return voices
  }

  return JSON.parse(cookieVoices)
}

export const getSpeechToken = async () => {
  const speechToken = Cookies.get('speech-token')

  if (!speechToken) {
    const { token, region } = await apiGet<{ token: string, region: string }>('/api/text-to-speech-token')

    const formattedCookie = `${token};${region}`

    Cookies.set('speech-token', formattedCookie, { expires: 1 / 24 / 6 })
    return { token, region }
  }

  const [token, region] = speechToken.split(';')

  return { token, region }
}

export const useSynthesizeSpeech = () => {
  const [{ lastLearnedLanguage }] = useUserState()
  const [, setGeneralState] = useGeneralState()
  const [{ audioEnabled }] = useCardState()
  const activeVoice = useGetActiveVoice()

  return async (text: string) => {
    if (lastLearnedLanguage && audioEnabled) {
      const { token, region } = await getSpeechToken()
      if (region && token && activeVoice) {
        const speechConfig = SpeechConfig.fromAuthorizationToken(token, region)
        speechConfig.speechSynthesisLanguage = activeVoice
        const player = new SpeakerAudioDestination()
        const audioConfig = AudioConfig.fromSpeakerOutput(player)
        const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig)

        const myPromise = new Promise((resolve, reject) => {
          synthesizer.speakTextAsync(
            text,
            (result) => {
              if (result) {
                synthesizer.close()
                resolve('Success')
                return result.audioData
              }
            },
            () => {
              reject()
              synthesizer.close()
            },
          )
        })

        await myPromise
        setGeneralState({ player })
      }
    }
  }
}

export const toggleAudioEnabled = (
  audioEnabled: boolean, setAudioEnabled: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const status = !audioEnabled
  setAudioEnabled(status)
  localStorage.setItem('audio-enabled', JSON.stringify(status))
}
