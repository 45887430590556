import axios from 'axios'

export async function apiGet<T = any>(url: string): Promise<T> {
  const { data } = await axios.get(url)
  return data
}

export async function apiPost<T = any>(url: string, body?: any): Promise<T> {
  const { data } = await axios.post(url, body)
  return data
}

export const apiDelete = async (url: string) => {
  const { data } = await axios.delete(url)
  return data
}

export const apiPut = async (url: string, body?: any) => {
  const { data } = await axios.put(url, body)
  return data
}
