import { createReducer } from '@reduxjs/toolkit'
import { IExtendedCard } from '../components/Screens/CardScreen/Card/Utils/CardTypes'

export type cardStateType = {
  cardFlipped: boolean
  showExampleSentences: boolean
  dueCardsCount: number | undefined
  cards: IExtendedCard[]
  audioEnabled: boolean
  history: IExtendedCard[]
  newExampleSentence: string
  shouldBlurSentences: boolean
  cardsFetched: boolean
  deckLessThan10: boolean
}

const initialState: cardStateType = {
  cardFlipped: false,
  showExampleSentences: false,
  dueCardsCount: undefined,
  cards: [],
  audioEnabled: true,
  history: [],
  newExampleSentence: '',
  shouldBlurSentences: true,
  cardsFetched: false,
  deckLessThan10: false,
}

export default createReducer(
  initialState,
  {
    'card/update': (state, action) => {
      const actionPayload: Partial<cardStateType> = action.payload
      if (actionPayload) {
        const newState = { ...state }

        newState.cardFlipped = actionPayload.cardFlipped ?? state.cardFlipped
        newState.showExampleSentences = actionPayload.showExampleSentences
          ?? state.showExampleSentences
        newState.dueCardsCount = actionPayload.dueCardsCount ?? state.dueCardsCount
        newState.cards = actionPayload.cards ?? state.cards
        newState.audioEnabled = actionPayload.audioEnabled ?? state.audioEnabled
        newState.history = actionPayload.history ?? state.history

        // REFACTOR move to example sentences reducer
        newState.newExampleSentence = actionPayload.newExampleSentence ?? state.newExampleSentence
        newState.cardsFetched = actionPayload.cardsFetched ?? state.cardsFetched
        newState.deckLessThan10 = actionPayload.deckLessThan10 ?? state.deckLessThan10

        newState.shouldBlurSentences = actionPayload.shouldBlurSentences
          ?? state.shouldBlurSentences

        return newState
      }
    },
    'card/reset': () => initialState,
  },
)
