import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import tps from '../../constants/trackingPoints'
import { signInWithGoogle } from '../../functions/authFunctions'
import purpleBlur from '../../images/purple-blur.png'
import googleSigninImage from '../../images/sign-in-with-google.png'
import googleSignUpImage from '../../images/sign-up-with-google.png'
import useSendTrackingPointOnPageLoad from '../../shared/hooks/analytics/useSendTrackingPointOnPageLoad'
import './LoginScreen.scss'

interface ILoginScreen {
  login?: boolean
  setStep?: React.Dispatch<React.SetStateAction<number>>
}

const LoginScreen: React.FC<ILoginScreen> = ({
  login = true, setStep,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)
  const signInWithGoogleWrapper = async () => {
    const signInStatus = await signInWithGoogle(dispatch, history, !login, setStep)
    if (signInStatus === 'Auth failed') {
      if (login) { setShowError(true) }
    }
  }

  useSendTrackingPointOnPageLoad(tps.signup_page3_seen)

  return (
    <div className="tmwo h-max w-full flex justify-center">
      <div className="md:max-w-3xl w-full justify-center flex flex-col items-center font-ibm-plex-sans">
        <div className="w-full flex flex-col items-center z-50">
          {login && <p className="text-3xl pb-6">Welcome back!</p>}
          <p className="text-xl">{`${!login ? 'Sign up for' : 'Login to'} Kaards`}</p>
          <button
            onClick={signInWithGoogleWrapper}
            type="button"
            className="w-full max-w-[300px] py-24"
          >
            <img
              src={login ? googleSigninImage : googleSignUpImage}
              className="clickable w-full md:w-300"
              alt="logo"
            />
          </button>
          {login && (
            <p className="text-sm">
              Don’t have an account yet?
              {' '}
              <Link to="/signup" className="underline">Sign up now</Link>
            </p>
          )}
          {showError && (
            <div className="flex flex-col items-center">
              <p className="text-red">
                Oops... Looks like you don&apos;t have an account.
              </p>
            </div>
          )}
        </div>
        {login
          && (
            <div className="absolute z-0 w-screen overflow-hidden flex justify-center h-screen">
              <img
                src={purpleBlur}
                alt="Purple background"
                style={{ minWidth: 800 }}
              />
            </div>
          )}
      </div>
    </div>
  )
}

export default LoginScreen
