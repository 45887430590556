import { IUser } from '../../../shared/src/sharedTypes'
import { apiPost } from './generic'

export const createTrackingPoint = (taskName: string, body?: any) => {
  try {
    const userLocalStorage = localStorage.getItem('KaardsAuth')
    const user = userLocalStorage ? JSON.parse(userLocalStorage) : body
    if (user) {
      // @ts-ignore
      window.analytics.track(taskName, body || user)
      apiPost('/api/loops', {
        user,
        eventName: taskName,
      })
    }
  } catch {
    console.error('createTrackingPoint failed')
  }
}

export const identifyUser = (user: IUser) => {
  try {
    if (user.handle) {
      // @ts-ignore
      window.analytics.identify(user.uid, { ...user, email: user.email })
    }
  } catch {
    console.error('identifyUser failed')
  }
}
