import transparentLogo from '../../images/kaards-transparent-logo.png'
import twitterIcon from './twitter.svg'

export const openUrl = (url: string, sameTab?: boolean) => {
  if (window?.open !== null) {
    // @ts-ignore
    window.open(url, sameTab ? '_self' : '_blank').focus()
  }
}
const TeaserSiteFooter = () => (
  <div className="bg-base-bars w-full tmwo">
    <div className="flex sm:flex-row flex-col justify-between py-24 md:py-44 sm:justify-start tmwi text-white text-center w-full gap-y-16">
      <div className="lhs sm:w-1/2 flex flex-col items-start gap-y-24">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={transparentLogo}
          alt="Kaards logo"
          style={{ height: 22 }}
          className="clickable"
          onClick={() => openUrl('https://app.kaards.io')}
        />
        <p className="text-light text-left" style={{ fontFamily: 'IBM Plex Mono, monospaced', fontSize: 12 }}>
          ©
          {' '}
          {(new Date()).getFullYear()}
          {' '}
          Kaards Inc. All rights reserved
        </p>
      </div>
      <div className="rhs flex flex-col sm:flex-row sm:items-end justify-end sm:w-1/2 gap-x-24 lg:gap-x-48 gap-y-16 pt-20">
        <a className="hover:text-white text-light cursor-pointer hover:underline text-left sm:text-center" style={{ fontSize: '14px' }} href="mailto:hello@kaards.io">
          Contact us
        </a>
        <a
          className="pr-5 hover:text-white cursor-pointer hover:underline text-left sm:text-center text-light"
          style={{ fontSize: '14px' }}
          href="https://www.termsfeed.com/live/37c0caea-1d3e-46f6-baec-1c0987af89c9"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
        <a
          className="pr-5 hover:text-white cursor-pointer hover:underline text-left sm:text-center text-light"
          style={{ fontSize: '14px' }}
          href="https://www.termsofservicegenerator.net/live.php?token=oM3VOReFbz8nZxvfmOcg4YkNrv31KQpQ"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        <div className="flex gap-x-24 items-center pt-12 sm:pt-0">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={twitterIcon}
            style={{ height: 20, width: 20 }}
            onClick={() => openUrl('https://twitter.com/kaards_io')}
            alt="twitter"
            className="clickable"
          />
        </div>
      </div>
    </div>
  </div>
)

export default TeaserSiteFooter
