// REFACTOR
import Loader from '../Loader'
import './SquareButton.scss'

interface ISquareButton {
  onClick?: () => any;
  color?: 'primary-default' | 'error';
  text?: string;
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
  textClasses?: string
  className?: string;
  div?: boolean
  disabled?: boolean
  submit?: boolean
  testid?: string
  isLoading?: boolean
}

const SquareButton: React.FC<ISquareButton> = ({
  onClick, color, text, style, className, innerStyle, div, disabled,
  submit = false, children, testid, isLoading, textClasses,
}) => {
  const sharedClasses = 'SquareButton flex gap-x-[8px] justify-center text-white rounded-md font-ibm-plex-sans h-fit p-[8px] items-center'

  return (
    !div
      ? (
        <button
          className={`
            ${sharedClasses} SquareButton-${color} 
            ${!disabled ? 'clickable' : 'cursor-not-allowed opacity-50'} 
            ${className}`}
          onClick={onClick && !isLoading ? onClick : undefined}
          style={style}
          type={submit ? 'submit' : 'button'}
          disabled={disabled}
          data-cy={testid}
        >
          {isLoading && <div><Loader /></div>}
          {text
            ? <p className={`${textClasses} SquareButton-innerText px-14 py-14`} style={innerStyle}>{text}</p>
            : children}
        </button>
      )
      : (
        <div
          className={`${sharedClasses} SquareButton-${color} clickable ${className}`}
          onClick={onClick && !isLoading ? onClick : undefined}
          style={style}
          data-cy={testid}
        >
          {isLoading && <div><Loader /></div>}
          {text
            ? (
              <p
                className={`${textClasses} SquareButton-innerText font-ibm-plex-sans`}
                style={innerStyle}
              >
                {text}
              </p>
            )
            : children}
        </div>
      )
  )
}

export default SquareButton
