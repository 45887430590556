import {
  lazy, Suspense, useEffect, useLayoutEffect, useState,
} from 'react'
import {
  Redirect, Route, Switch,
} from 'react-router-dom'
import '../node_modules/flag-icons/css/flag-icons.min.css'
import './App.scss'
import LoginSignupLayout from './components/Layouts/LoginSignupLayout'
import LoginScreen from './components/LoginScreen/LoginScreen'
import { getSpeechToken } from './components/Screens/CardScreen/Card/Utils/CardAudioUtils'
import Signup from './components/Signup/Signup'
import './core-styles.scss'
import { setAxiosAuthToken } from './functions/authFunctions'
import { identifyUser } from './functions/juneAnalytics'
import { passCurrentLanguage } from './functions/languageFunctions'
import './index.css'
import useGetUser from './shared/hooks/auth/useGetUser'

const AuthenticatedComponents = lazy(() => import('./AuthenticatedComponents'))

const AllRoutes: React.FC<any> = ({ authUid }) => {
  const user = useGetUser()

  useEffect(() => {
    if (user?.uid) {
      getSpeechToken()
      identifyUser(user)
    }
  }, [user])

  if (authUid === false || user.subscription === 'onboarding') {
    return (
      <Switch>
        <Route path="/login">
          <LoginSignupLayout isLogin>
            <LoginScreen />
          </LoginSignupLayout>
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Redirect to="/login" />
      </Switch>
    )
  }
  return <AuthenticatedComponents />
}

const App: React.FC = () => {
  const [authUid, setAuthUid] = useState<false | string | null>(null)

  const user = useGetUser()

  useLayoutEffect(() => {
    passCurrentLanguage(user.lastLearnedLanguage)
  }, [user.lastLearnedLanguage])

  useEffect(() => {
    if (user.uid) {
      setAxiosAuthToken(true)
      setAuthUid(user.uid)
      localStorage.setItem('KaardsAuth', JSON.stringify(user))
    } else {
      setAxiosAuthToken(false)
      setAuthUid(false)
    }

    const localStorageUser = localStorage.getItem('KaardsAuth')
    const parsedLocalStorageUser = localStorageUser ? JSON.parse(localStorageUser) : null

    if (parsedLocalStorageUser?.uid) {
      setAxiosAuthToken(true)
      setAuthUid(parsedLocalStorageUser.uid)
    }
  }, [user])

  return (
    <Suspense fallback={<div>Loading</div>}>
      <AllRoutes authUid={authUid} />
    </Suspense>
  )
}

export default App
