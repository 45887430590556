import firebase from 'firebase/app'
import 'firebase/auth'
import { useDispatch } from 'react-redux'
import { setAxiosAuthToken } from '../../functions/authFunctions'

const useSignout = () => {
  const dispatch = useDispatch()

  return () => {
    firebase.auth().signOut()
    dispatch({ type: 'user/logout' })
    localStorage.removeItem('KaardsAuth')
    setAxiosAuthToken(false)
    window.location.href = '/login'
  }
}

export default useSignout
