import { useState } from 'react'
import LoginScreen from '../LoginScreen/LoginScreen'
import SignupFlowLayout from './SignupFlowLayout'
import SignupUsernameScreen from './SignupUsernameScreen'

export interface SignUpLanguageData {
  [language: string]: { level: string, goal: string }
}

const Signup: React.FC = () => {
  const [step, setStep] = useState(1)
  return (
    <div className="bg-base-dark min-h-screen flex flex-col gap-y-12">
      {step === 1
        && (
          <SignupFlowLayout step={step}>
            <LoginScreen login={false} setStep={setStep} />
          </SignupFlowLayout>
        )}
      {step === 2
        && (
          <SignupFlowLayout step={step}>
            <SignupUsernameScreen setStep={setStep} />
          </SignupFlowLayout>
        )}
      {/* {step === 3
        && (
          <SignupFlowLayout step={step}>
            <SignupLevelScreen />
          </SignupFlowLayout>
        )} */}
    </div>
  )
}

export default Signup
