import { useDispatch, useSelector } from 'react-redux'
import { IUser } from '../../../../shared/src/sharedTypes'
import { State } from '../store'

export default () => {
  const dispatch = useDispatch()
  const setUserState = (payload: Partial<IUser>) => dispatch({ type: 'user/update', payload })
  const userState = useSelector<State, IUser>((state) => state.user)
  return [userState, setUserState] as const
}
