import kaardsLogoTransparent from '../../images/kaards-transparent-logo.png'
import useSignout from '../../shared/hooks/useSignout'
import TeaserSiteFooter from '../TeaserSite/TeaserSiteFooter'

const LoginSignupLayout: React.FC<{ footer?: boolean, isLogin?: boolean }> = ({ children, footer, isLogin }) => {
  const signOut = useSignout()
  return (
    <div className="h-full min-h-screen overflow-hidden flex flex-col items-center bg-black w-screen">
      <div>
        <div className="bg-base-bars w-screen flex justify-center py-18 tmwo items-center z-50">
          <div className="tmwi h-full flex items-center">
            <button
              type="button"
              onClick={signOut}
            >
              <img
                src={kaardsLogoTransparent}
                className="h-22 clickable"
                alt="Kaards transparent logo"
              />
            </button>
          </div>
        </div>
      </div>
      <div className={`h-full w-full flex md:items-center ${isLogin && 'items-center'} justify-center md:bg-base-section flex-grow relative overflow-hidden`}>
        {children}
      </div>
      {footer && (
        <div className="w-full">
          <TeaserSiteFooter />
        </div>
      )}
    </div>
  )
}

export default LoginSignupLayout
