import { combineReducers, createStore } from 'redux'
import { IUser } from '../../../shared/src/sharedTypes'
import cardReducer, { cardStateType } from './cardReducer'
import deckReducer, { DecksState } from './deckReducer'
import generalReducer, { GeneralStateType } from './generalReducer'
import sessionReducer, { SessionStateType } from './sessionReducer'
import userReducer from './userReducer'
import wordListReducer, { wordlistStateType } from './wordListReducer'

export const allReducers = combineReducers({
  user: userReducer,
  deck: deckReducer,
  general: generalReducer,
  wordlist: wordListReducer,
  card: cardReducer,
  session: sessionReducer,
})

const store = createStore(allReducers)

export default store

export interface State {
  user: IUser,
  deck: DecksState
  general: GeneralStateType
  wordlist: wordlistStateType
  card: cardStateType
  session: SessionStateType
}
