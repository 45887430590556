import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import './assets/main.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ProviderLayer from './ProviderLayer'
import store from './redux/store'

Sentry.init({
  dsn: 'https://de36451125174becaa5019d89e7ebcd8@o4504548090707968.ingest.sentry.io/4504548092542976',
  integrations: [new BrowserTracing()],
  enabled: process.env.NODE_ENV !== 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <StrictMode>
    <Router getUserConfirmation={() => { }}>
      <Provider store={store}>
        <ProviderLayer />
      </Provider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
)
