/* eslint-disable react/no-unescaped-entities */
import LoginSignupLayout from '../Layouts/LoginSignupLayout'
import SignupFlowLayoutStep from './SignupFlowLayoutStep'

const SignupFlowLayout: React.FC<{ step: number }> = ({ children, step }) => (
  <LoginSignupLayout>
    <div className={`flex flex-col md:flex-row max-w-7xl md:h-[75vh] md:mx-20 font-ibm-plex-sans w-full justify-start h-full md:justify-center ${step !== 3 && 'justify-between'}`}>
      <div className="flex flex-col bg-dark-iris w-full md:w-[70vw] md:rounded-l-lg p-32 md:max-w-sm  justify-between">
        <div>
          <div className="flex flex-col gap-12 justify-between">
            <div className="flex flex-col gap-y-12 md:pb-28 justify-between w-1/2 md:w-full">
              <p className="text-lg md:text-3xl font-medium">Learn with Kaards</p>
              <p className="text-sm md:text-base">You're just a few steps away from exponentially growing your vocabulary 📈</p>
            </div>
            <div className="flex flex-col gap-y-12 md:justify-between">
              <SignupFlowLayoutStep title="Sign up" step={1} currentStep={step} />
              <SignupFlowLayoutStep title="Username" step={2} currentStep={step} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:bg-base-dark flex flex-col md:rounded-r-lg p-32 w-full max-w-[700px] justify-center">
        {children}
      </div>
    </div>
  </LoginSignupLayout>
)

export default SignupFlowLayout
