import DarkIrisCheck from '../../assets/icons/dark-iris-checkmark.svg'

interface ISignupFlowLayoutStep {
  title: string
  step: number
  currentStep: number
}

const SignupFlowLayoutStep: React.FC<ISignupFlowLayoutStep> = ({ title, step, currentStep }) => (
  <div className={`flex gap-x-16 items-center ${currentStep < step && 'opacity-50'}`}>
    <div
      className="border border-white rounded-full h-24 w-24 md:h-28 md:w-28 flex items-center justify-center"
    >
      {currentStep > step
        ? <img src={DarkIrisCheck} alt="Checkmark" />
        : <p className="pl-1 text-sm md:text-base">{step}</p>}
    </div>
    <p className="text-sm md:text-base">{title}</p>
  </div>
)

export default SignupFlowLayoutStep
