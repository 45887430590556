const tps = {
  // signup page
  signup_page1_seen: 'signup_page1_seen',
  new_user_alert: 'new_user_alert',
  signup_page2_seen: 'signup_page2_seen',
  signup_page3_seen: 'signup_page3_seen',

  // onboarding conversion
  onboardingSubscriptionScreen_seen: 'onboardingSubscriptionScreen_seen',
  onboardingOptions_freeTrialButton_clicked: 'onboardingOptions_freeTrialButton_clicked',

  // login
  login: 'login',
}

export default tps
