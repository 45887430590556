import { createReducer } from '@reduxjs/toolkit'
import { wordListCard } from '../../../shared/src/services/wordListService'
import { SortingStateType } from '../components/WordList/wordlistTypes'

export type wordlistStateType = {
  words: wordListCard[],
  searchInput: string,
  sorting: SortingStateType,
  hasMore: boolean,
  isLoading: boolean,
  isSorting: boolean
  isSearching: boolean
  deckId: string | undefined
  isCustomDeck: boolean
}

const initialState: wordlistStateType = {
  words: [],
  sorting: { level: -1 },
  hasMore: true,
  isLoading: false,
  searchInput: '',
  isSorting: false,
  isSearching: false,
  deckId: undefined,
  isCustomDeck: false,
}

export default createReducer(
  initialState,
  {
    'wordlist/update': (state, action) => {
      const actionPayload: Partial<wordlistStateType> = action.payload
      if (actionPayload) {
        const newState = { ...state }

        newState.words = actionPayload.words ?? state.words
        newState.sorting = actionPayload.sorting ?? state.sorting
        newState.hasMore = actionPayload.hasMore ?? state.hasMore
        newState.isLoading = actionPayload.isLoading ?? state.isLoading
        newState.searchInput = actionPayload.searchInput ?? state.searchInput
        newState.isSorting = actionPayload.isSorting ?? state.isSorting
        newState.isSearching = actionPayload.isSearching ?? state.isSearching
        newState.isCustomDeck = actionPayload.isCustomDeck ?? state.isCustomDeck
        newState.deckId = actionPayload.deckId || state.deckId

        return newState
      }
    },
    'wordlist/reset': (state) => ({
      ...initialState,
      isLoading: state.isLoading,
    }),
    'wordlist/changeDeck': (state, action) => {
      const actionPayload: Partial<wordlistStateType> = action.payload
      if (actionPayload) {
        return {
          ...initialState,
          isCustomDeck: actionPayload.isCustomDeck ?? state.isCustomDeck,
          deckId: actionPayload.deckId || state.deckId,
        }
      }
    },
  },
)
