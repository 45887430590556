import toast from 'react-hot-toast'
import { animated, useTransition } from 'react-spring'
import tealCheck from '../../assets/icons/TealCheck.svg'
import xIcon from '../../assets/icons/x-circle.svg'
import StarIcon from '../../assets/icons/Star.svg'
import Icon from './Icon/Icon'

interface IToastrTemplate {
  title: string
  description: string
  iconName: string | any
  iconClasses: string
  maxWidth?: boolean
  buttonAction?: () => void
  buttonTitle?: string
  secondButtonTitle?: string
  iconStyle?: any
  hideIcon?: boolean
  secondButtonAction?: () => void
  isVisible: boolean
}

export const ToastrTemplate: React.FC<IToastrTemplate> = ({
  title, description, iconName, iconClasses, maxWidth, iconStyle,
  buttonAction, buttonTitle, secondButtonAction, secondButtonTitle, hideIcon = true, isVisible,
}) => {
  const outerTransitions = useTransition(
    isVisible,
    {
      from: { opacity: '0%' },
      enter: { opacity: '100%' },
      leave: { opacity: '0%' },
      reverse: isVisible,
      config: { duration: 200 },
    },
  )

  return outerTransitions(
    (styles, item) => item && (
      <animated.div
        className={`bg-base-bars font-ibm-plex-sans flex gap-x-24 p-24 rounded-lg sm:m-24 mb-[24px] ${maxWidth && 'max-w-sm'} w-fit justify-between`}
        style={styles}
      >
        <div className="flex gap-x-24 w-full">
          <div className={`${hideIcon ? 'hidden' : 'flex'} md:flex items-center w-max`}>
            <Icon
              importedImage={iconName}
              icon={iconName}
              innerStyles={iconStyle}
              size={30}
              className={`${iconClasses}`}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-between w-full gap-x-24 gap-y-24">
            <div className="flex flex-col text-left">
              <p className={`${iconClasses} font-semibold pb-8`} style={{ fontSize: 16 }}>{title}</p>
              <p className="text-light text-sm">{description}</p>
            </div>
            {(buttonTitle || secondButtonTitle) && (
              <div className="flex gap-x-16 sm:items-center flex-col sm:flex-row items-start gap-y-12">
                {buttonTitle
                  && (
                    <button
                      onClick={buttonAction}
                      type="button"
                      className="p-16 rounded-xl bg-primary-default text-light hover:text-white hover:bg-dark-iris h-min sm:w-min w-full justify-center flex"
                    >
                      <p className="text-sm w-max">{buttonTitle}</p>
                    </button>
                  )}
                {secondButtonTitle
                  && (
                    <button
                      onClick={secondButtonAction}
                      type="button"
                      className="p-16 rounded-xl border border-light  text-light hover:text-white hover:bg-base-section  h-min sm:w-min w-full justify-center flex"
                    >
                      <p className="text-sm w-max">{secondButtonTitle}</p>
                    </button>
                  )}
              </div>
            )}
          </div>
        </div>
      </animated.div>
    ),
  )
}

export const toastr = {
  success: (title: string, description: string) => toast.custom(
    (t) => (
      <ToastrTemplate
        title={title}
        description={description}
        iconName={tealCheck}
        iconClasses="text-light-teal"
        hideIcon={false}
        maxWidth
        isVisible={t.visible}
      />
    ),
    {
      position: 'bottom-left',
      duration: 4000,
      id: title,
    },
  ),
  error: (title?: string, description?: string) => toast.custom(
    (t) => (
      <ToastrTemplate
        title={title || 'Oops...'}
        description={description || 'Something went wrong. Please refresh the page try again.'}
        iconName={xIcon}
        iconClasses="text-red"
        hideIcon={false}
        iconStyle={{ filter: 'invert(42%) sepia(66%) saturate(1306%) hue-rotate(323deg) brightness(84%) contrast(92%)' }}
        maxWidth
        isVisible={t.visible}
      />
    ),
    { position: 'bottom-left', duration: 4000, id: title },
  ),
  info: (title: string, description: string) => toast.custom(
    (t) => (
      <ToastrTemplate
        title={title}
        description={description}
        iconName={StarIcon}
        iconStyle={{ filter: 'invert(27%) sepia(50%) saturate(2500%) hue-rotate(180deg) brightness(90%) contrast(92%)' }}
        hideIcon
        maxWidth
        isVisible={t.visible}
        iconClasses=""
      />
    ),
    { position: 'bottom-left', duration: 4000, id: title },
  ),
}
