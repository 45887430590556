import { FaSpinner } from 'react-icons/fa'

interface ILoader {
  className?: string
}

const Loader: React.FC<ILoader> = ({ className = '' }) => (
  <div className={`flex h-full w-full justify-center items-center ${className}`}>
    <FaSpinner className="animate-spin" />
  </div>
)

export default Loader
