// REFACTOR
/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUser } from '../../../../shared/src/sharedTypes'
import tps from '../../constants/trackingPoints'
import { apiGet, apiPut } from '../../functions/generic'
import { State } from '../../redux/store'
import useSendTrackingPointOnPageLoad from '../../shared/hooks/analytics/useSendTrackingPointOnPageLoad'
import SquareButton from '../Atoms/SquareButton/SquareButton'
import { toastr } from '../Atoms/Toastr'
import { renderCheckButton, userHandleInputChange } from './signupUtils'

interface ISignupUsernameScreen {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

type userHandleAvailability = '' | 'available' | 'unavailable' | 'too short' | 'too long' | 'no special characters';

const SignupUsernameScreen: React.FC<ISignupUsernameScreen> = ({ setStep }) => {
  const [userHandleAvailable, setUserHandleAvailable] = useState<userHandleAvailability>('')
  const user = useSelector<State, IUser>((state) => state.user)
  const [userHandle, setUserHandle] = useState<string>('')
  const dispatch = useDispatch()

  const handleNext = async () => {
    const data = await apiGet(`/checkHandle/${userHandle}`)

    if (data === 'available') {
      apiPut('/api/user', {
        uid: user.uid,
        handle: userHandle,
        subscription: '',
      })
      dispatch({ type: 'user/update', payload: { ...user, subscription: '' } })

      setStep((step) => step + 1)
    } else {
      toastr.error("Sorry... that name isn't available.")
    }
  }

  useSendTrackingPointOnPageLoad(tps.signup_page1_seen)

  return (
    <div>
      <p className="text-3xl">Let's get started.</p>
      <p className="text-3xl">What should we call you?</p>
      <p className="text-lg text-gray-400 bg-transparent pt-32 pb-16 font-semibold">Enter a username</p>
      <div className="relative">
        <input
          onChange={(e) => userHandleInputChange(e, setUserHandle, setUserHandleAvailable)}
          value={userHandle}
          placeholder="Username"
          className={`
          w-full  mt-3 text-white rounded-md
          text-md px-16 py-8 focus:outline-none
          md:max-w-[392px] bg-base-section border border-gray focus-within:border-iris
          ${renderCheckButton(userHandleAvailable) && 'border-red'}
          `}
        />
      </div>
      {renderCheckButton(userHandleAvailable)
        && <p className="text-xs text-gray-400 pb-5 font-ibm-plex-mono text-red pt-8">{renderCheckButton(userHandleAvailable)}</p>}
      <div className="w-full h-fit">
        <SquareButton
          onClick={handleNext}
          text="Next"
          className="bg-dark-iris hover:bg-iris md:max-w-[190px] mt-16 rounded-lg h-fit py-[0px]"
          disabled={!!renderCheckButton(userHandleAvailable) || !userHandle.length}
        />
      </div>
    </div>
  )
}

export default SignupUsernameScreen
