const DB_CONFIG = {
  apiKey: 'AIzaSyCWxM_r8851nKnp6p34BoIWUTXmOlfRNeU',
  authDomain: 'kaards-c5f66.firebaseapp.com',
  projectId: 'kaards-c5f66',
  storageBucket: 'kaards-c5f66.appspot.com',
  messagingSenderId: '561824073885',
  appId: '1:561824073885:web:667fa18f4b4e8a826fac74',
  measurementId: 'G-XZ06ESW676',
}

export default DB_CONFIG
