import { useDispatch, useSelector } from 'react-redux'
import { GeneralStateType } from '../generalReducer'
import { State } from '../store'

export default () => {
  const dispatch = useDispatch()
  const setGeneralState = (payload: Partial<GeneralStateType>) => dispatch({ type: 'general/update', payload })
  const generalState = useSelector<State, GeneralStateType>((state) => state.general)
  return [generalState, setGeneralState] as const
}
