import { apiPut } from '../../functions/generic'
import useUserState from '../../redux/hooks/useUserState'
import { VoiceType, useGetAvailableVoices } from '../Screens/CardScreen/Card/Utils/CardAudioUtils'

const getVoicesForLanguage = (
  voices: VoiceType[] | undefined, lastLearnedLanguage: string,
) => voices
  ?.filter((voice) => voice.Locale.split('-')[0] === lastLearnedLanguage?.toLowerCase())
  ?.reduce((acc: VoiceType[], val: VoiceType) => (
    acc.find((voice) => voice.Locale === val.Locale)
      ? acc
      : [...acc, val]
  ), [])

export const useGetActiveVoice = () => {
  const defaultVoices: Record<string, string> = {
    ES: 'es-ES',
    DE: 'de-DE',
    FR: 'fr-FR',
    PT: 'pt-BR',
    'zh-Hans': 'zh-CN',
    'zh-Hant': 'zh-TW',
    yue: 'zh-HK',
  }

  const [{ lastLearnedLanguage, languageSettings }] = useUserState()
  const { data } = useGetAvailableVoices()

  if (!lastLearnedLanguage) return

  const availableLanguages = getVoicesForLanguage(data?.token, lastLearnedLanguage)

  const savedLocale = languageSettings?.[lastLearnedLanguage]?.locale
  const defaultLocale = defaultVoices[lastLearnedLanguage]
  const firstLocale = availableLanguages?.[0]?.Locale

  return savedLocale || defaultLocale || firstLocale
}

const AccentSetting = () => {
  const [{ lastLearnedLanguage }, setState] = useUserState()
  const { data } = useGetAvailableVoices()

  const voicesForLanguage = data?.token
    ?.filter((voice) => voice.Locale.split('-')[0] === lastLearnedLanguage?.toLowerCase())
    ?.reduce((acc: VoiceType[], val: VoiceType) => (
      acc.find((voice) => voice.Locale === val.Locale)
        ? acc
        : [...acc, val]
    ), [])

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = async (e) => {
    const user = await apiPut('/api/user/settings', { lastLearnedLanguage, settings: { locale: e.target.value } })
    setState(user)
  }

  const activeVoice = useGetActiveVoice()

  if (!voicesForLanguage || voicesForLanguage.length < 2) return null

  return (
    <div className="flex flex-col py-[20px] w-full justify-between gap-x-[32px] border-b border-base-bars">
      <div className="flex flex-col w-full gap-y-[8px]">
        <p className=" text-[16px]">Accent</p>
        <p className="text-light text-[12px]">Choose the accent your cards are read in</p>
        <select
          className="bg-base-section border-gray border text-sm py-[8px] px-[12px] rounded-md"
          onChange={onChange}
          defaultValue={activeVoice}
        >
          {voicesForLanguage.map((voice) => (
            <option value={voice.Locale} key={voice.Locale}>{voice.LocaleName}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default AccentSetting
