import { createReducer } from '@reduxjs/toolkit'
import { levelType } from '../components/Screens/DecksScreen/DecksScreen'

export interface DecksState {
  deckUid: string;
  level: levelType;
  isCustomDeckModalOpen: boolean
}

const initialState: DecksState = {
  deckUid: '',
  level: 1,
  isCustomDeckModalOpen: false,
}

const reducer = createReducer(initialState, {
  'deck/study': (state, action) => {
    state.deckUid = action.payload.uid
  },
  'deck/update': (state, action) => {
    if (action.payload) {
      const newState: DecksState = {
        ...state,
      }
      newState.deckUid = action.payload.deckUid || state.deckUid
      newState.level = action.payload.level || state.level
      newState.isCustomDeckModalOpen = action.payload.isCustomDeckModalOpen
        ?? state.isCustomDeckModalOpen
      return newState
    }
  },
})

export default reducer
