import axios from 'axios'
import { apiGet } from '../../functions/generic'

// eslint-disable-next-line import/prefer-default-export
export const userHandleInputChange = async (
  e: React.ChangeEvent<HTMLInputElement>, setUserHandle: any,
  setUserHandleAvailable: any,
) => {
  const specialCharRegex = new RegExp('[^A-Za-z0-9]')
  const userInput = e.target.value.toLowerCase().split(' ').join('')
  setUserHandle(userInput)
  if (userInput.match(specialCharRegex)) {
    setUserHandleAvailable('no special characters')
  } else if (userInput.length > 15) {
    setUserHandleAvailable('too long')
  } else if (userInput.length < 2) {
    setUserHandleAvailable('too short')
  } else if (userInput.length > 2 && userInput.length < 15) {
    const data = await apiGet(`/checkHandle/${userInput}`)
    setUserHandleAvailable(data)
  }
}

export const submitDetails = (
  e: React.MouseEvent, userHandleAvailable: any, userHandle: any, userLanguages: any, dispatch: any,
) => {
  e.preventDefault()

  if (userHandleAvailable === 'available') {
    axios
      .post('/api/user/finishOnboarding', {
        handle: userHandle,
        learning: userLanguages,
      })
      .then((val: object) => {
        if (val) {
          dispatch({
            type: 'user/update',
            payload: {
              handle: userHandle,
              learning: userLanguages,
              status: 'onboarded',
            },
          })
        }
      })
  }
}

export const renderCheckButton = (userHandleAvailable: any) => {
  switch (userHandleAvailable) {
    case 'available':
      return false
    case 'unavailable':
      return <p>Name unavailable </p>
    case 'too short':
      return <p>The username should be between 2 and 15 characters </p>
    case 'too long':
      return <p>The username should be between 2 and 15 characters </p>
    case 'no special characters':
      // eslint-disable-next-line react/no-unescaped-entities
      return <p>Special characters aren't allowed</p>
    default:
      break
  }
}
