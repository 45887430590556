import { Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import DB_CONFIG from '../config/firebase'
import tps from '../constants/trackingPoints'
import { apiGet, apiPost } from './generic'
import { createTrackingPoint } from './juneAnalytics'

export const initializeAuth = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(DB_CONFIG)
  }
  return firebase.auth()
}

const auth = initializeAuth()

// set or remove userId in header for all axios requests
export const setAxiosAuthToken = async (login = true) => {
  // sign out
  if (!login) return false

  // sign in / auth change
  if (auth.currentUser) {
    const idToken = await auth.currentUser.getIdToken()

    if (axios.defaults.headers.common.Authorization !== idToken) {
      axios.defaults.headers.common.Authorization = idToken
    }

    const remoteUserStatus = await apiPost('/userStatus', { uid: auth.currentUser?.uid })
    return remoteUserStatus
  }
}

export const signInWithGoogle: any = async (
  dispatch: Dispatch<any>, history: any, allowSignup: boolean = false,
  setStep?: React.Dispatch<React.SetStateAction<number>>,
) => {
  const provider = new firebase.auth.GoogleAuthProvider()
  const userCredentials = await auth.signInWithPopup(provider)

  if (userCredentials) {
    const status = await setAxiosAuthToken(true)
    if (typeof status === 'string') {
      const userResponse = await apiGet(`/api/user/auth/${userCredentials.user?.uid}`)
      createTrackingPoint(tps.login)
      return dispatch({ type: 'user/update', payload: userResponse })
    }

    if (allowSignup && setStep) {
      await setAxiosAuthToken(true)
      const createdUser = await apiPost('/user', {
        email: userCredentials.user?.email,
        uid: userCredentials.user?.uid,
        subscription: 'onboarding',
      })
      setStep(2)
      createTrackingPoint(tps.new_user_alert, createdUser)
      return dispatch({ type: 'user/update', payload: createdUser })
    }

    return 'Auth failed'
  }
}

export const signOut = (setAuthUid: any, dispatch: any) => {
  auth.signOut()
  setAxiosAuthToken(false)
  setAuthUid(false)
  localStorage.removeItem('KaardsAuth')
  dispatch({ type: 'user/logout' })
}

export const getUserInformation = async (
  uid: string, dispatch: any, signOutFxn: () => void,
) => {
  try {
    const user = await apiGet(`/api/user/${uid}`)

    if (!user) {
      throw new Error('User not found')
    }

    dispatch({ type: 'user/update', payload: user })
  } catch {
    signOutFxn()
  }
}
