import { createReducer } from '@reduxjs/toolkit'
import { DeckIdType, IUser } from '../../../shared/src/sharedTypes'

const initialState: IUser = {
  uid: '',
  handle: '',
  email: '',
  signUpDate: new Date(),
  subscription: '',
  lastLearnedLanguage: undefined,
  isFoundingMember: false,
  subscriptionExpiration: undefined,
  customerId: undefined,
  trialEndsAt: undefined,
}

export default createReducer(initialState, {
  'user/update': (state, action) => {
    if (action.payload) {
      const newState: IUser = {
        ...state,
      }

      newState.uid = action.payload.uid || state.uid
      newState.handle = action.payload.handle || state.handle
      newState.email = action.payload.email || state.email
      newState.signUpDate = action.payload.signUpDate || state.signUpDate

      newState.subscription = action.payload.subscription !== undefined
        ? action.payload.subscription : state.subscription

      newState.subscriptionExpiration = action.payload.subscriptionExpiration
        || state.subscriptionExpiration
      newState.shouldSubscriptionAutoRenew = action
        .payload.shouldSubscriptionAutoRenew !== undefined
        ? action.payload.shouldSubscriptionAutoRenew
        : state.shouldSubscriptionAutoRenew
      newState.subscriptionId = action.payload.subscriptionId || state.subscriptionId
      newState.goals = action.payload.goals || state.goals
      newState.lastLearnedLanguage = action.payload.lastLearnedLanguage || state.lastLearnedLanguage
      newState.languageSettings = action.payload.languageSettings || state.languageSettings
      newState.subscriptionExpiration = action.payload.subscriptionExpiration
        || state.subscriptionExpiration
      newState.customerId = action.payload.customerId || state.customerId
      newState.trialEndsAt = action.payload.trialEndsAt || state.trialEndsAt
      newState.studiedLanguages = action.payload.studiedLanguages || state.studiedLanguages

      return newState
    }
  },
  'user/logout': (state) => {
    state.uid = ''
    state.handle = ''
    state.email = ''
  },
  'user/goals': (state, action) => {
    const { sessionType, value, deckId } = action.payload

    state.goals = {
      ...state.goals,
      [deckId]: {
        ...state.goals?.[deckId as DeckIdType] || [],
        [sessionType]: value,
      },
    }
  },
})
